import React from "react";
// mui
import { Box, IconButton, Tooltip } from "@mui/material";
// icons
import DeleteIcon from "@mui/icons-material/Delete";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
// icons
import ApiIcon from "@mui/icons-material/Api";
import CodeIcon from "@mui/icons-material/Code";
import DrawIcon from "@mui/icons-material/Draw";
import GridOnIcon from "@mui/icons-material/GridOn";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// custom component
import { BstHeaderField } from "./CustomComponents/muiComponents";
import { DocTypes } from "./constants";

export default function BstHeader(props) {
  const {
    heading,
    docType,
    writePermission,
    setHeading,
    handleHeadingChange,
    subDocumentId,
    isExpanded,
    handleOpenConfirm,
    handleExpand,
    fullScreen,
    handleFullScreen,
    provided,
  } = props;

  const isFullscreen = fullScreen?.[subDocumentId];

  const getDocTypeIcon = (docType) => {
    switch (docType) {
      case DocTypes.document:
        return <NoteAddIcon color="primary" />;
      case DocTypes.sheet:
        return <PostAddIcon color="secondary" />;
      case DocTypes.table:
        return <GridOnIcon color="secondary" />;
      case DocTypes.flow:
        return <AccountTreeIcon color="green" />;
      case DocTypes.draw:
        return <DrawIcon color="yellow" />;
      case DocTypes.media:
        return <PermMediaIcon color="orange" />;
      case DocTypes.code:
        return <CodeIcon color="grey" />;
      case DocTypes.api:
        return <ApiIcon color="purple" />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        m: 1,
      }}
    >
      <Box
        onDoubleClick={() =>
          handleExpand(subDocumentId, !isExpanded || isFullscreen)
        }
        sx={{ mr: 1, display: "flex", alignItems: "center", cursor: "grab" }}
        {...provided.dragHandleProps}
      >
        {getDocTypeIcon(docType)}
      </Box>
      <BstHeaderField
        placeholder="Heading..."
        variant="standard"
        value={heading}
        disabled={!writePermission}
        onChange={(e) => {
          setHeading(e.target.value);
          handleHeadingChange(heading, subDocumentId);
        }}
        sx={{ width: "95%" }}
      />
      <Box sx={{ display: "flex" }}>
        <Tooltip
          title={isFullscreen ? "Exit Full Screen" : "Enter Full Screen"}
          arrow
        >
          <IconButton
            onClick={() => handleFullScreen(subDocumentId, !isFullscreen)}
            sx={{ p: 0, mr: 1, ml: 1 }}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete document">
          <IconButton
            edge="end"
            aria-label="delete"
            disabled={!writePermission}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering ListItemButton's onClick
              handleOpenConfirm(subDocumentId);
            }}
            sx={{ p: 0, mr: 1, ml: 1 }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
