import React, { createContext, useState, useEffect, useMemo } from "react";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import getTheme from "../theme";
import axiosInstance from "./axiosInstance";

export const ThemeContext = createContext();

export const BstThemeProvider = ({ children }) => {
  const [mode, setMode] = useState("dark");

  // Function to fetch mode from API
  const fetchModeFromAPI = async () => {
    try {
      const response = await axiosInstance.get("/user/settings/"); // Replace with your API endpoint
      const data = await response.json();
      return data.darkMode ? "dark" : "light"; // Assuming the API returns { mode: "light" or "dark" }
    } catch (error) {
      console.error("Failed to fetch theme mode from API:", error);
      return "light"; // Fallback to a default mode in case of an error
    }
  };

  // Load theme mode from localStorage or API
  useEffect(() => {
    const loadMode = async () => {
      const storedMode = localStorage.getItem("theme-mode");
      if (storedMode) {
        setMode(storedMode);
      } else {
        const apiMode = await fetchModeFromAPI();
        setMode(apiMode);
        localStorage.setItem("theme-mode", apiMode);
      }
    };
    loadMode();
  }, []);

  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("theme-mode", newMode);
      return newMode;
    });
  };

  const theme = useMemo(() => getTheme(mode), [mode]);

  return (
    <ThemeContext.Provider value={{ toggleTheme, mode }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
