import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Avatar,
  Popover,
  Typography,
  // Tooltip,
  // IconButton,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// icons
// import ShareIcon from "@mui/icons-material/Share";
import WbSunnyIcon from "@mui/icons-material/WbSunny"; // Sun icon
import NightlightRoundIcon from "@mui/icons-material/NightlightRound"; // Moon icon

import axiosInstance from "./axiosInstance";
import { useNavigate } from "react-router";
import { ThemeContext } from "./BstThemeProvider";

export default function BstMainHeader(props) {
  const {
    documentName,
    setDocumentName,
    writePermission,
    documentId,
    userData,
    // handleShareOpen,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;
  const { toggleTheme, mode } = useContext(ThemeContext);

  const handleToggleTheme = async () => {
    try {
      toggleTheme();
      await axiosInstance.patch("/user/settings", {
        darkMode: mode === "dark",
      });
    } catch {
      console.log("error");
    }
  };

  const navigate = useNavigate();

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update document name
  const handleNameChange = async (e) => {
    const newName = e.target.value;
    setDocumentName(newName);
    try {
      await axiosInstance.put(`/doc/${documentId}/`, { name: newName });
    } catch (error) {
      console.error("Failed to update document name:", error);
    }
  };

  // logout
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  const IconSwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 5,
    "& .MuiSwitch-switchBase": {
      padding: 5,
      "&.Mui-checked": {
        transform: "translateX(22px)",
        color: theme.palette.common.white,
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 24,
      height: 24,
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiSwitch-track": {
      borderRadius: 20,
      backgroundColor: theme.palette.grey[400],
    },
  }));

  return (
    <Box sx={{ ml: 5, mt: 1, display: "flex" }}>
      <Box sx={{ width: "90%" }}>
        <TextField
          sx={{ mt: 1, width: "90%", maxWidth: "356px" }}
          placeholder="Main Heading"
          variant="standard"
          value={documentName}
          disabled={!writePermission}
          onChange={(newValue) => handleNameChange(newValue)}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <IconSwitch
          checked={mode === "dark"}
          onChange={handleToggleTheme}
          inputProps={{ "aria-label": "Theme toggle switch" }}
          icon={<WbSunnyIcon color="yellow" />} // Sun icon for light mode
          checkedIcon={<NightlightRoundIcon />} // Moon icon for dark mode
        />
      </Box>
      {/* <Tooltip title={"share"} arrow>
        <IconButton
          sx={{ ml: 1 }}
          color="primary"
          onClick={handleShareOpen}
          disabled={!writePermission}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip> */}
      <Avatar
        sx={{
          ml: 2,
          backgroundSize: "small",
          cursor: "pointer",
          bgcolor: "pink.main",
        }}
        onClick={handleAvatarClick}
      >
        {userData?.name?.charAt(0).toUpperCase()}
      </Avatar>

      {/* Popover with User Info */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{userData?.name}</Typography>
          <Typography variant="body2">{userData?.email}</Typography>
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            color="secondary"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
