import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import {
  createRef,
  React,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// components
import Layout from "./Layout/layout";
import axiosInstance from "./axiosInstance";
import { Box, IconButton } from "@mui/material";
// Bst Data Forms
import BstCode from "./BstCode";
import BstDraw from "./BstDraw";
import BstSheet from "./BstSheet";
import BstEditor from "./BstEditor";
import BstFlow from "./Flow/BstFlow";
// utils
import { companyName, DocTypes } from "./constants";
import BstDeleteConfirm from "./BstDeleteConfirm";
import BstShare from "./BstShare";
import BstFabs from "./BstFabs";
import BstMainHeader from "./BstMainHeader";
import BstApiMain from "./BstApi/main";
import BstTable from "./BstTable";
//icons
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BstMedia from "./BstMedia";
import { toastError } from "../utils/tools";
import { ThemeContext } from "./BstThemeProvider";

export default function Bst() {
  const { id: documentId } = useParams();
  const [documentName, setDocumentName] = useState();
  const [subDocuments, setSubDocuments] = useState([]);
  const [open, setOpen] = useState(true);
  const [toLayout, setLayout] = useState(true);
  const [permission, setPermission] = useState("read");
  const [userData, setUserData] = useState({ name: "", email: "" });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);
  const [expand, setExpand] = useState({});
  const [fullScreen, setFullScreen] = useState({});
  const handleShareOpen = () => setShareOpen(true);
  const handleShareClose = () => setShareOpen(false);
  // eslint-disable-next-line no-unused-vars
  const [copiedSubDoc, setCopiedSubDoc] = useState(null);
  const [selectedSubDocId, setSelectedSubdocId] = useState(null);
  const [selectedSubDocIndex, setSelectedSubdocIndex] = useState(0);
  const [undoStack, setUndoStack] = useState([]);
  const [expandSource, setExpandSource] = useState(false);
  const { mode } = useContext(ThemeContext);

  const subDocRefs = useRef([]);
  const writePermission = permission === "write";

  useEffect(() => {
    document.title = documentName + "-" + companyName;
  });

  useEffect(() => {
    if (subDocuments.length !== subDocRefs.current.length) {
      subDocRefs.current = subDocuments.map(() => createRef());
    }
  }, [subDocuments]);

  // handle heading change
  const handleHeadingChange = async (heading, subDocumentId) => {
    try {
      await axiosInstance.put(`/sub-doc/${subDocumentId}/`, {
        heading: heading,
      });
      updateSubDocumentsData(heading, subDocumentId, "heading");
    } catch (error) {
      console.error("Failed to update document heading:", error);
    }
  };

  //  SUBDOCUMENT CRUD OPERATIONS
  // Create
  const handleCreateSubDoc = useCallback(
    async (data, heading, docType) => {
      try {
        const response = await axiosInstance.post("sub-doc", {
          heading: heading,
          data: data,
          docType: docType,
          documentId: documentId,
        });
        const { subDocument } = response.data;
        setExpand((prevExpand) => ({
          ...prevExpand,
          [subDocument._id]: subDocument.isExpanded,
        }));
        setFullScreen((prevFullScreen) => ({
          ...prevFullScreen,
          [subDocument._id]: false,
        }));
        setSubDocuments([...subDocuments, subDocument]);
      } catch (error) {
        toastError("Failed to create subdocument", error);
      }
    },
    [documentId, subDocuments]
  );

  // Read (Load) subdocuments
  useEffect(() => {
    const loadDocument = async () => {
      try {
        const response = await axiosInstance.post(`/doc/${documentId}`);
        const { doc, subDocs } = response.data;
        setDocumentName(doc.name);
        setPermission(doc.accessLevel);
        setUserData({ name: doc.owner.name, email: doc.owner.email });
        setExpand(loadExpand(subDocs));
        setSubDocuments(subDocs);
        setSelectedSubdocIndex(0);
        setSelectedSubdocId(subDocs?.[0]?._id);
        setExpandSource(doc.expandSource);
      } catch (error) {
        toastError("Failed to load the document", error);
      }
    };

    if (documentId) {
      loadDocument();
    }
  }, [documentId]);

  // Update (save) subdocuments
  const updateSubDocumentsData = (data, subDocumentId, field) => {
    setSubDocuments((subDocuments) =>
      subDocuments.map((item) =>
        item._id === subDocumentId
          ? { ...item, [field]: data } // Merge new data with the existing object
          : item
      )
    );
  };
  const saveDocument = useCallback(async (data, subDocumentId) => {
    try {
      await axiosInstance.put(`/sub-doc/${subDocumentId}`, {
        data: data,
      });
      updateSubDocumentsData(data, subDocumentId, "data");
    } catch (error) {
      toastError("Failed to update", error);
    }
  }, []);

  // Update order of subdocs
  const saveNewOrder = async (updatedSubDocs) => {
    try {
      await axiosInstance.patch("/sub-doc/save-order", {
        subDocs: updatedSubDocs,
      });
    } catch (error) {
      toastError("Order change failed", error);
    }
  };

  // Delete Subdocuments
  const handleSubDocumentDelete = async (docId) => {
    try {
      const documentToBeDeleted = subDocuments.find(
        (item) => item._id === docId
      );
      setUndoStack((undoStack) => [...undoStack, documentToBeDeleted]);

      await axiosInstance.delete(`/sub-doc/${docId}`);

      let updatedSubDocs = subDocuments.filter((item) => item._id !== docId);
      updatedSubDocs = updatedSubDocs.map((doc, index) => ({
        ...doc,
        order: index,
      }));
      setSubDocuments(updatedSubDocs);
      saveNewOrder(updatedSubDocs);
      const newSelectedSubDocIndex =
        selectedSubDocIndex > 0 ? selectedSubDocIndex - 1 : 0;
      setSelectedSubdocIndex(newSelectedSubDocIndex);
      setSelectedSubdocId(subDocuments[newSelectedSubDocIndex]?._id);
    } catch (error) {
      console.error("Failed to delete document", error);
    }
  };

  const handleOpenConfirm = (id) => {
    setDocToDelete(id);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setDocToDelete(null);
  };

  const handleConfirmDelete = () => {
    handleSubDocumentDelete(docToDelete);
    handleCloseConfirm();
  };

  // shortcut to delete selected document
  useEffect(() => {
    const handleOpenConfirm = (id) => {
      setDocToDelete(id);
      setConfirmOpen(true);
    };
    const handleKeyDown = (event) => {
      if (event.altKey) {
        switch (event.key) {
          case "w":
            handleOpenConfirm(selectedSubDocId);
            event.preventDefault();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  // Expand and full screen
  const loadExpand = (subDocuments) => {
    let expand = {};
    for (let i in subDocuments) {
      const subDoc = subDocuments[i];
      expand[subDoc._id] = subDoc.isExpanded;
    }
    return expand;
  };

  const loadFullscreen = (subDocuments) => {
    let fullScreen = {};
    for (let i in subDocuments) {
      const subDoc = subDocuments[i];
      fullScreen[subDoc._id] = false;
    }
    return fullScreen;
  };

  const handleExpand = useCallback(
    async (subDocumentId, minimize) => {
      setExpand((prevState) => ({
        ...prevState,
        [subDocumentId]: minimize,
      }));
      const subDocument = subDocuments.find(
        (item) => item._id === subDocumentId
      );
      handlSelectSubDoc(subDocument.data, subDocument);
      try {
        await axiosInstance.put(`/sub-doc/${subDocumentId}`, {
          isExpanded: minimize,
        });
      } catch (error) {
        toastError("Expanding failed", error);
      }
    },
    [subDocuments]
  );

  const handleFullScreen = async (subDocumentId, state) => {
    setFullScreen((prevState) => ({
      ...prevState,
      [subDocumentId]: state,
    }));
  };

  const expandAll = async (expand) => {
    const updatedExpand = {};
    subDocuments.forEach((subDoc) => {
      updatedExpand[subDoc._id] = expand;
    });

    // Update state in one batch
    setExpand(updatedExpand);

    // Update all documents in parallel
    try {
      await Promise.all(
        subDocuments.map((subDoc) => handleExpand(subDoc._id, expand))
      );
    } catch (error) {
      console.log("Error expanding all:", error);
    }
  };

  const handlSelectSubDoc = (data, subDoc) => {
    setCopiedSubDoc(data);
    setSelectedSubdocId(subDoc._id);
    setSelectedSubdocIndex(subDoc.order);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    // If no destination, return
    if (!destination) return;

    // If the item is dropped at the same place, return
    if (destination.index === source.index) return;

    // Rearrange the list based on the drag result
    const reorderedSubDocs = Array.from(subDocuments);
    const [removed] = reorderedSubDocs.splice(source.index, 1);
    reorderedSubDocs.splice(destination.index, 0, removed);

    // Update the order property based on the new position
    const updatedSubDocs = reorderedSubDocs.map((doc, index) => ({
      ...doc,
      order: index,
    }));

    setSubDocuments(updatedSubDocs);

    saveNewOrder(updatedSubDocs);
  };

  // handle copy and paste
  // Keyboard event handler
  useEffect(() => {
    const handleCopy = () => {
      const subDocumentToCopy = subDocuments[selectedSubDocIndex]; // Replace with logic to select the subdocument
      const serializedData = JSON.stringify(subDocumentToCopy);
      // navigator.clipboard.writeText(serializedData).then(() => {
      //   console.log("Copied to clipboard:", subDocumentToCopy);
      // });
      localStorage.setItem("copiedSubDocument", serializedData);
    };

    const handlePaste = () => {
      try {
        const serializedData = localStorage.getItem("copiedSubDocument");
        if (!serializedData) {
          toastError("Error pasting!");
          return;
        }
        const newSubDocument = JSON.parse(serializedData);
        handleCreateSubDoc(
          newSubDocument.data,
          newSubDocument.heading,
          newSubDocument.docType
        );
        console.log("Pasted subdocument:", newSubDocument);
      } catch (err) {
        toastError("Error pasting!");
      }
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === "C") {
        // Copy
        event.preventDefault();
        handleCopy();
      }
      if (event.ctrlKey && event.shiftKey && event.key === "V") {
        // Paste
        event.preventDefault();
        handlePaste();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [subDocuments, selectedSubDocIndex, handleCreateSubDoc]);

  // move up and down in the documents
  // useEffect to handle keyboard shortcuts
  useEffect(() => {
    const scrollTo = (newSelectedSubDocIndex) => {
      // Scroll the selected sub-document into view
      subDocRefs.current[newSelectedSubDocIndex]?.current?.scrollIntoView({
        behavior: "smooth", // Scroll smoothly
        block: "start", // Scroll to the center of the view
      });
    };
    const handleKeyDown = (e) => {
      let newSelectedSubDocIndex = selectedSubDocIndex || 0;
      const isAnyFullScreen = Object.values(fullScreen).some(
        (value) => value === true
      );
      const isMac = navigator.userAgent.includes("Mac");

      if (!toLayout) {
        if (
          ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
          e.key === "ArrowDown" &&
          selectedSubDocIndex < subDocuments.length - 1 &&
          !isAnyFullScreen
        ) {
          e.preventDefault();
          newSelectedSubDocIndex = selectedSubDocIndex + 1;
          setSelectedSubdocIndex(newSelectedSubDocIndex);
          setSelectedSubdocId(subDocuments[newSelectedSubDocIndex]?._id);
          scrollTo(newSelectedSubDocIndex);
        } else if (
          ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
          e.key === "ArrowUp" &&
          selectedSubDocIndex > 0 &&
          !isAnyFullScreen
        ) {
          e.preventDefault();
          newSelectedSubDocIndex = selectedSubDocIndex - 1;
          setSelectedSubdocIndex(newSelectedSubDocIndex);
          setSelectedSubdocId(subDocuments[newSelectedSubDocIndex]?._id);
          scrollTo(newSelectedSubDocIndex);
        }

        // Reorder sub-documents with Ctrl + Alt + ArrowUp / ArrowDown
        else if (
          e.altKey &&
          e.key === "ArrowDown" &&
          selectedSubDocIndex < subDocuments.length - 1
        ) {
          e.preventDefault();
          const reorderedSubDocs = Array.from(subDocuments);
          // Move selected doc down
          const [movedDoc] = reorderedSubDocs.splice(selectedSubDocIndex, 1);
          reorderedSubDocs.splice(selectedSubDocIndex + 1, 0, movedDoc);

          const updatedSubDocs = reorderedSubDocs.map((doc, index) => ({
            ...doc,
            order: index,
          }));

          setSubDocuments(updatedSubDocs);
          setSelectedSubdocIndex(selectedSubDocIndex + 1);
          setSelectedSubdocId(updatedSubDocs[selectedSubDocIndex + 1]._id);
          saveNewOrder(updatedSubDocs);
          scrollTo(selectedSubDocIndex + 1);
        } else if (e.altKey && e.key === "ArrowUp" && selectedSubDocIndex > 0) {
          const reorderedSubDocs = Array.from(subDocuments);
          // Move selected doc up
          const [movedDoc] = reorderedSubDocs.splice(selectedSubDocIndex, 1);
          reorderedSubDocs.splice(selectedSubDocIndex - 1, 0, movedDoc);

          const updatedSubDocs = reorderedSubDocs.map((doc, index) => ({
            ...doc,
            order: index,
          }));

          setSubDocuments(updatedSubDocs);
          setSelectedSubdocIndex(selectedSubDocIndex - 1);
          setSelectedSubdocId(updatedSubDocs[selectedSubDocIndex - 1]._id);
          saveNewOrder(updatedSubDocs);
          scrollTo(selectedSubDocIndex - 1);
        }
      }
    };

    const expandAll = async (expand) => {
      // Create a new expanded state for all sub-documents
      const updatedExpand = subDocuments.reduce((acc, subDoc) => {
        acc[subDoc._id] = expand;
        return acc;
      }, {});

      // Update the state immediately
      setExpand(updatedExpand);

      // Perform the API updates in parallel without blocking the state update
      try {
        await Promise.all(
          subDocuments.map((subDoc) => handleExpand(subDoc._id, expand))
        );
      } catch (error) {
        console.error("Error expanding all:", error);
      }
    };

    const handleExpandShortCuts = (e) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (((isMac && e.metaKey) || (!isMac && e.ctrlKey)) && e.shiftKey) {
        if (e.key === "ArrowRight") {
          expandAll(true);
        } else if (e.key === "ArrowLeft") {
          expandAll(false);
        }
      }
    };

    const handleCreateSubDoc = async (data, heading, docType) => {
      try {
        const response = await axiosInstance.post("sub-doc", {
          heading: heading,
          data: data,
          docType: docType,
          documentId: documentId,
        });
        const { subDocument } = response.data;
        const updatedSubDocuments = [...subDocuments, subDocument];
        setExpand(loadExpand(updatedSubDocuments));
        setFullScreen(loadFullscreen(updatedSubDocuments));
        setSubDocuments(updatedSubDocuments);
      } catch (error) {
        toastError("Failed to create subdocument", error);
      }
    };

    const handleUndo = async () => {
      if (undoStack.length === 0) return;
      const topSubDocument = undoStack.pop();
      setUndoStack([...undoStack]);
      const { data, heading, docType } = topSubDocument;
      await handleCreateSubDoc(data, heading, docType);
    };

    const handleUndoShortCut = (e) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (
        ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
        e.shiftKey &&
        e.key === "Z"
      ) {
        e.preventDefault();
        handleUndo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleExpandShortCuts);
    window.addEventListener("keydown", handleUndoShortCut);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keydown", handleExpandShortCuts);
      window.removeEventListener("keydown", handleUndoShortCut);
    };
  }, [
    toLayout,
    selectedSubDocId,
    selectedSubDocIndex,
    subDocuments,
    fullScreen,
    undoStack,
    documentId,
    handleExpand,
  ]);

  const renderBstComponent = (subDocument, expand, provided) => {
    if (subDocument === "") return null;

    // Common props for all components
    const commonProps = {
      subDocument: subDocument,
      handleHeadingChange: handleHeadingChange,
      handleSubDocumentDelete: handleSubDocumentDelete,
      updateSubDocumentsData: updateSubDocumentsData,
      handleOpenConfirm: handleOpenConfirm,
      writePermission: writePermission,
      handleExpand: handleExpand,
      fullScreen: fullScreen,
      selectedSubDocId,
      handleFullScreen: handleFullScreen,
      expand: expand,
      handlSelectSubDoc: handlSelectSubDoc,
      saveDocument: saveDocument,
      provided: provided,
      open: open,
      toLayout: toLayout,
      expandSource: expandSource,
    };

    switch (subDocument.docType) {
      case DocTypes.document:
        return <BstEditor {...commonProps} docType={DocTypes.document} />;
      case DocTypes.sheet:
        return <BstSheet {...commonProps} docType={DocTypes.sheet} />;
      case DocTypes.flow:
        return <BstFlow {...commonProps} docType={DocTypes.flow} />;
      case DocTypes.draw:
        return <BstDraw {...commonProps} docType={DocTypes.draw} />;
      case DocTypes.media:
        return <BstMedia {...commonProps} docType={DocTypes.media} />;
      case DocTypes.code:
        return <BstCode {...commonProps} docType={DocTypes.code} />;
      case DocTypes.api:
        return <BstApiMain {...commonProps} docType={DocTypes.api} />;
      case DocTypes.table:
        return <BstTable {...commonProps} docType={DocTypes.table} />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Layout
        expand={expand}
        selectedSubDocId={selectedSubDocId}
        subDocuments={subDocuments}
        setSelectedSubdocId={setSelectedSubdocId}
        setSelectedSubdocIndex={setSelectedSubdocIndex}
        open={open}
        setOpen={setOpen}
        toLayout={toLayout}
        setLayout={setLayout}
      >
        <Box sx={{ mb: 1 }} onClick={() => setLayout(false)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {/* Top text and search bar */}
              <BstMainHeader
                documentName={documentName}
                subDocuments={subDocuments}
                setDocumentName={setDocumentName}
                writePermission={writePermission}
                handleCreateSubDoc={handleCreateSubDoc}
                documentId={documentId}
                userData={userData}
                handleShareOpen={handleShareOpen}
              />
              <Box sx={{ mt: 2 }} />
              {/* Bst Create Buttons */}
              <BstFabs
                writePermission={writePermission}
                handleCreateSubDoc={handleCreateSubDoc}
                expandAll={expandAll}
              />

              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    width: expandSource ? "75%" : "100%",
                    pr: 1,
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: "82vh",
                      overflow: "auto",
                      scrollbarWidth: "none",
                      borderRadius: "10px",
                      pb: 1,
                    }}
                  >
                    {/* Bst Components */}
                    <Box>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="subDoc">
                          {(provided) => (
                            <Box
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {subDocuments.map((subDocument, index) => {
                                return (
                                  <Draggable
                                    key={subDocument._id}
                                    draggableId={subDocument._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <Box
                                        sx={{ display: "flex" }}
                                        ref={subDocRefs.current[index]}
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            handleExpand(
                                              subDocument._id,
                                              !expand[subDocument._id]
                                            );
                                            e.target.blur();
                                          }}
                                          sx={{
                                            maxHeight: 50,
                                          }}
                                        >
                                          {expand[subDocument._id] ? (
                                            <KeyboardArrowDownIcon />
                                          ) : (
                                            <KeyboardArrowRightIcon />
                                          )}
                                        </IconButton>
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          width="100%"
                                          borderRadius="10px"
                                          sx={{ mt: 1 }}
                                          border={
                                            !toLayout &&
                                            index === selectedSubDocIndex &&
                                            mode === "dark"
                                              ? "1px solid #2196f3"
                                              : "none"
                                          }
                                          boxShadow={
                                            // selectedSubDocId === subDocument._id
                                            !toLayout &&
                                            index === selectedSubDocIndex
                                              ? mode === "light"
                                                ? "0px 2px 2px rgba(0, 0, 0, 0.5)"
                                                : "none"
                                              : "none"
                                          }
                                        >
                                          {renderBstComponent(
                                            subDocument,
                                            expand,
                                            provided
                                          )}
                                        </Box>
                                      </Box>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </Box>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <ToastContainer />
        </Box>
      </Layout>

      <BstDeleteConfirm
        confirmOpen={confirmOpen}
        handleCloseConfirm={handleCloseConfirm}
        handleConfirmDelete={handleConfirmDelete}
      />

      <BstShare
        shareOpen={shareOpen}
        handleShareOpen={handleShareOpen}
        handleShareClose={handleShareClose}
        documentId={documentId}
      />
    </Box>
  );
}
