import {
  List,
  ListItem,
  // ListItemText,
  Divider,
  styled,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "../axiosInstance";
import DocumentList from "./DocumentList";
import { companyName, drawerWidth } from "../constants";
import { ObjectId } from "bson";

//icons
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import ReorderIcon from "@mui/icons-material/Reorder";

export default function Layout(props) {
  const {
    children,
    open,
    setOpen,
    expand,
    selectedSubDocId,
    toLayout,
    setLayout,
  } = props;
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  // const [sharedDocuments, setSharedDocuments] = useState([]);
  const { id } = useParams();
  const [selectedDocId, setSelectedDocId] = useState(id);
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);

  useEffect(() => {
    if (documents.length > 0 && selectedDocId) {
      const docIndex = documents.findIndex((doc) => doc._id === selectedDocId);
      if (docIndex !== -1) {
        setSelectedDocIndex(docIndex); // Set the index if document is found
      }
    }
  }, [documents, selectedDocId]);

  useEffect(() => {
    document.title = companyName;
  });

  const fetchDocuments = async () => {
    try {
      const documents = await axiosInstance.get("/doc");
      setDocuments(documents.data);
    } catch {
      console.log("something went wrong");
    }
  };

  // const fetchSharedDocuments = async () => {
  //   try {
  //     const documents = await axiosInstance.get("/doc/share/");
  //     setSharedDocuments(documents.data);
  //   } catch {
  //     console.log("something went wrong");
  //   }
  // };

  useEffect(() => {
    fetchDocuments();
    // fetchSharedDocuments();
  }, []);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0, // Hide the drawer when closed
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const handleDrawer = () => {
    setOpen(!open);
    setLayout(!toLayout);
  };

  const generateId = () => {
    const newObjectId = new ObjectId().toString();
    console.log("Generated ObjectId:", newObjectId);
    return newObjectId;
  };

  const handleCreateNewDoc = async () => {
    const newDocId = generateId();
    setSelectedDocId(newDocId);
    const response = await axiosInstance.post(`/doc/${newDocId}`);
    const { doc } = response.data;
    setDocuments([...documents, doc]);
    navigate(`/documents/${newDocId}`);
  };

  const handleDelete = async (docId) => {
    try {
      await axiosInstance.delete(`/doc/${docId}`);
      fetchDocuments();
      // fetchSharedDocuments();
      navigate("/documents");
    } catch (error) {
      console.error("Failed to delete document", error);
    }
  };

  // Handle document click
  const handleDocumentClick = (docId) => {
    setSelectedDocId(docId);
    navigate(`/documents/${docId}`);
  };

  // logout
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (
        toLayout &&
        (e.key === "Escape" ||
          (((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
            e.key === "ArrowRight"))
      ) {
        // setOpen(false);
        setLayout(false);
      } else if (
        !toLayout &&
        expand &&
        !expand[selectedSubDocId] &&
        ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
        e.key === "ArrowLeft"
      ) {
        setOpen(true);
        setLayout(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, setOpen, expand, selectedSubDocId, toLayout, setLayout]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      let newSelectedDocIndex = selectedDocIndex || 0;
      const isMac = navigator.userAgent.includes("Mac");
      if (
        toLayout &&
        ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
        e.key === "ArrowDown" &&
        selectedDocIndex < documents.length - 1
      ) {
        newSelectedDocIndex = selectedDocIndex + 1;
      } else if (
        toLayout &&
        ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
        e.key === "ArrowUp" &&
        selectedDocIndex > 0
      ) {
        newSelectedDocIndex = selectedDocIndex - 1;
      } else if (
        toLayout &&
        e.key === "Enter" &&
        documents[newSelectedDocIndex]._id !== selectedDocId
      ) {
        const newDocId = documents[newSelectedDocIndex]._id;
        setSelectedDocId(newDocId);
        navigate(`/documents/${newDocId}`);
      }
      if (newSelectedDocIndex !== selectedDocIndex) {
        setSelectedDocIndex(newSelectedDocIndex);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [toLayout, documents, selectedDocId, selectedDocIndex, navigate]);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <IconButton
          onClick={handleDrawer}
          sx={{
            position: "absolute",
            top: 12,
            left: 5,
            zIndex: 1300,
          }}
        >
          <ReorderIcon />
        </IconButton>
        <Drawer
          variant="permanent"
          open={open}
          onClick={() => {
            try {
              setLayout(true);
            } catch {
              console.log("error");
            }
          }}
        >
          <List>
            <ListItem
              onClick={handleDrawer}
              sx={{
                justifyContent: "center",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <img src="/pessoa-logo.png" alt="Icon" style={{ width: 100 }} />
              </Box>
            </ListItem>
          </List>

          {open && (
            <>
              <Divider />
              <ListItem
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleCreateNewDoc}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  fullWidth
                  style={{
                    textTransform: "none",
                    display: open ? "flex" : "none",
                    borderRadius: "10px",
                  }}
                >
                  Create new doc
                </Button>
              </ListItem>
              <DocumentList
                open={open}
                documents={documents}
                selectedDocIndex={selectedDocIndex}
                selectedDocId={selectedDocId}
                handleDelete={handleDelete}
                handleDocumentClick={handleDocumentClick}
                shared={false}
                toLayout={toLayout}
              />
              {/* {sharedDocuments.length !== 0 && (
                <ListItem
                  sx={{
                    display: "block",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <ListItemText
                    // primary={"Shared with You"}
                    sx={{
                      opacity: open ? 1 : 0,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      p: 0,
                      m: 0,
                    }}
                  />
                </ListItem>
              )}
              <DocumentList
                open={open}
                documents={sharedDocuments}
                selectedDocId={selectedDocId}
                selectedDocIndex={selectedDocIndex}
                handleDelete={handleDelete}
                handleDocumentClick={handleDocumentClick}
                shared={true}
              /> */}
              <Box sx={{ marginTop: "auto" }}>
                <ListItem
                  sx={{
                    p: 0,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={handleLogout}
                    fullWidth
                    startIcon={<LogoutIcon />}
                    sx={{
                      p: 2,
                      display: open ? "flex" : "none",
                    }}
                  >
                    Logout
                  </Button>
                  <IconButton
                    edge="end"
                    aria-label="create"
                    color="primary"
                    sx={{
                      display: open ? "none" : "flex",
                      m: 0,
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                </ListItem>
              </Box>
            </>
          )}
        </Drawer>
        <Box sx={{ width: "100%", pl: 1, pr: 1 }}>{children}</Box>
      </Box>
    </Box>
  );
}
